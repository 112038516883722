import React from "react"
import Layout from "../components/layout"

const IndexPage = ({ data }) => {
  return (
    < Layout landingPage = "usa-new-york" />
  )
}

export default IndexPage
